<template>
  <el-dialog width="25%" :title="title" :visible.sync="showDialog">
    <el-form :model="form" label-width="90px">
      <el-form-item label="菜单名称">
        <el-input v-model="form.name" placeholder="请输入菜单名称"></el-input>
      </el-form-item>
      <el-form-item label="菜单URL">
        <el-input v-model="form.url" placeholder="请输入菜单URL"></el-input>
      </el-form-item>
      <el-form-item label="菜单PATH">
        <el-input v-model="form.path" placeholder="请输入菜单PATH"></el-input>
      </el-form-item>
      <el-form-item label="菜单图标">
        <el-input v-model="form.css" placeholder="请输入和菜单图标"></el-input>
      </el-form-item>
      <el-form-item label="是否隐藏">
        <el-radio-group v-model="form.hidden">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否为菜单">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序号">
        <el-input
          type="number"
          v-model="form.sort"
          placeholder="请输入排序号"
        ></el-input>
      </el-form-item>
      <el-form-item label="上级菜单">
        <el-select v-model="form.parentId">
          <el-option value="-1" label="顶部目录"> </el-option>
          <el-option
            v-for="item in allMenus"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="mini" @click="showDialog = false">取 消</el-button>
      <el-button size="mini" type="primary" @click="editMenu" :loading="loading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "addDictType",
  props: ["editForm", "allMenus", "type"],
  mounted() {},
  data() {
    return {
      loading: false,
      showDialog: false,
      form: {
        css: "",
        hidden: false,
        id: "",
        name: "",
        parentId: "-1",
        path: "",
        pathMethod: "",
        sort: "4",
        tenantId: "internal",
        type: 1,
        url: "",
      },
      title: "添加菜单选项",
    };
  },
  methods: {
    async editMenu() {
      this.loading = true;
      await this.$api.systemConfig
        .editMenu(this.type, this.form)
        .finally(() => {
          this.loading = false;
        });
      this.showDialog = false;
      this.$emit("getMenusList");
    },
  },
  watch: {
    showDialog(newVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
      } else {
        this.editForm && Object.assign(this.form, this.editForm);
      }
    },
    editForm: {
      deep: true,
      handler(newVal) {
        this.title = `${newVal ? "编辑" : "添加"}菜单选项`;
      },
    },
  },
};
</script>