<template>
  <div class="contatain">
    <div class="flex-row">
      <el-select
        size="mini"
        style="width: 130px"
        class="m-r-10"
        v-model="params.tenantId"
        @change="getMenusList"
      >
        <el-option
          v-for="item in searchKeys"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        ></el-option>
      </el-select>
      <!-- <el-input
        size="mini"
        style="width: 130px"
        class="m-r-10"
        v-model="params.searchValue"
      />
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-search"
        :loading="loading"
        @click="getMenusList"
        >搜索</el-button
      > -->
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="openDialog('addMenu')"
        >添加</el-button
      >
      <!-- <el-button size="mini" type="primary" @click="expandKeys = allKeysId"
        >全部展开</el-button
      >
      <el-button size="mini" type="primary" @click="expandKeys.splice(0, 10)"
        >全部折叠</el-button
      > -->
    </div>
    <div class="content-wrap">
      <el-card class="row2-card">
        <el-table
          border
          :data="menusList"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          max-height="650"
          v-loading="loading"
          row-key="id"
          :expand-row-keys="expandKeys"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <!-- <el-table-column prop="id" label="ID" width="180"> </el-table-column> -->
          <el-table-column
            prop="name"
            label="菜单名称"
            width="300"
          ></el-table-column>
          <el-table-column label="菜单url" width="260" prop="url">
          </el-table-column>
          <el-table-column prop="path" label="菜单path" width="230">
          </el-table-column>
          <el-table-column
            prop="css"
            label="样式"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="sort"
            label="排序号"
            width="80"
          ></el-table-column>
          <el-table-column label="类型" width="90">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.type === 1" type="success">菜单</el-tag>
              <el-tag v-else type="info">资源</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="openDialog('addMenu', scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteMenu(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <add-menu ref="addMenu" @getMenusList="getMenusList" :editForm="editForm" :allMenus="allMenus" :type="params.tenantId" />
  </div>
</template>
<script>
import { debounce, timestampToDate } from "@tools";
import addMenu from "./common/components/addMenu";
export default {
  components: {
    addMenu,
  },
  data() {
    this.searchKeys = [
      { value: "internal", label: "编辑端" },
      { value: "system", label: "系统端" },
      { value: "customer", label: "用户端" },
    ];
    return {
      loading: false,
      allMenus: [],
      menusList: [],
      params: {
        tenantId: "internal",
      },
      count: 0,
      editForm: null,
      menusMap: {},
      allKeysId: [],
      expandKeys: [],
    };
  },
  async created() {
    this.getMenusList();
  },
  mounted() {},
  methods: {
    getMenusList: debounce(async function () {
      this.loading = true;
      const data = await this.$api.systemConfig
        .getMenus(this.params)
        .finally(() => {
          this.loading = false;
        });
      this.allMenus = data;
      this.menusMap = this.makeMap(data);
      this.menusList = this.makeTree(this.menusMap[-1]);
    }, 600),
    transTime(time) {
      return timestampToDate(time);
    },
    openDialog(ref, form) {
      this.editForm = form ? form : null
      this.$refs[ref].showDialog = true;
    },
    deleteMenu(id) {
      this.$confirm("此操作将永久删除该菜单选项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.$api.systemConfig.deleteMenu(this.params.tenantId, id);
          this.$message.success('删除成功')
          this.getMenusList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    makeMap(res) {
      return res.reduce((pre, cur) => {
        if (pre[cur.parentId]) {
          pre[cur.parentId].push(cur);
        } else {
          pre[cur.parentId] = [cur];
        }
        return pre;
      }, {});
    },
    makeTree(list) {
      if (!list) return [];
      return list.map((item) => {
        this.allKeysId.push(item.id);
        return {
          ...item,
          children: this.menusMap[item.id]
            ? this.makeTree(this.menusMap[item.id])
            : [],
        };
      });
    },
  },
  watch: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
</style>